var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home-view"},[_c('div',{staticClass:"home-view__wrapper"},[_c('Section',[_c('HeroSlider',{directives:[{name:"show",rawName:"v-show",value:(_vm.heroSlider.length > 0),expression:"heroSlider.length > 0"}],attrs:{"params":_vm.heroSlider}})],1),_c('transition',{attrs:{"name":"fade"}},[(_vm.showArticles)?_c('Section',[_c('ReasonsVisitArea',[_c('template',{slot:"reasons-visit-area-title"},[_c('h2',{staticClass:"reasons-visit-area__title"},[_vm._v(_vm._s(_vm.$t('home.reasons'))+" "+_vm._s(_vm.$city.title))])])],2)],1):_vm._e()],1),(_vm.samoeInteresnoeVGorode.length > 0)?_c('Section',[_c('ThematicSlider',{attrs:{"params":{array: _vm.samoeInteresnoeVGorode,
        component: 'PlaceCard',
        count: _vm.count}}},[_c('template',{slot:"thematic-slider-title"},[_c('h2',{staticClass:"thematic-slider__title"},[_vm._v(_vm._s(_vm.$t('home.interesting')))])]),_c('template',{slot:"thematic-slider-button"},[_c('Row',{staticClass:"thematic-slider__see-all",attrs:{"align":"center"}},[_c('router-link',{staticClass:"thematic-slider__see-all-text",attrs:{"to":`${_vm.$locale.base}/places#category-1`}},[_vm._v(" "+_vm._s(_vm.$laptop ? _vm.$t('home.watch_all.p_1') : '')+" "+_vm._s(_vm.$t('home.watch_all.p_2'))+" ")]),_c('Icon',{staticClass:"thematic-slider__see-all-icon",attrs:{"viewport":"0 0 20 20","rotate":90,"xlink":"arrow-navigation"}})],1)],1)],2)],1):_vm._e(),(_vm.$showIfDefaultCity)?_c('Section',{attrs:{"limiter":!_vm.$mobile}},[_c('FormationPortal',{attrs:{"backgroundImage":_vm.$laptop ?
        require('@/assets/images/panels.jpg') : ''}},[_c('template',{slot:"formation-portal-sup-title"},[_c('span',{staticClass:"formation-portal__sup-title"},[_vm._v(_vm._s(_vm.$t('home.banner.infrastructure')))])]),_c('template',{slot:"formation-portal-title"},[_c('span',{staticClass:"formation-portal__title"},[_vm._v(_vm._s(_vm.$t('home.banner.panels')))])]),_c('template',{slot:"formation-portal-description"},[_c('span',{staticClass:"formation-portal__description"},[_vm._v(_vm._s(_vm.$t('home.banner.desc.p_1'))+" "),_c('br'),_vm._v(" "+_vm._s(_vm.$t('home.banner.desc.p_2')))])]),_c('Button',{attrs:{"full":_vm.$mobile,"color":"transparent"},nativeOn:{"click":function($event){return _vm.toLink(`${_vm.$locale.base}/info`)}}},[_vm._v(" "+_vm._s(_vm.$mobile ? _vm.$t('home.banner.btn_2') : _vm.$t('home.banner.btn_1'))+" ")])],2)],1):_vm._e(),(_vm.ozdorovlenieVZheleznovodske.length > 0)?_c('Section',[_c('ThematicSlider',{attrs:{"params":{
          array: _vm.ozdorovlenieVZheleznovodske,
          component: 'PlaceCard',
          count: _vm.count
        }}},[_c('template',{slot:"thematic-slider-title"},[_c('h2',{staticClass:"thematic-slider__title"},[_vm._v(" "+_vm._s(_vm.$t('home.health'))+" "+_vm._s(_vm.$city.declension)+" ")])]),_c('template',{slot:"thematic-slider-button"},[_c('Row',{staticClass:"thematic-slider__see-all",attrs:{"align":"center"}},[_c('router-link',{staticClass:"thematic-slider__see-all-text",attrs:{"to":`${_vm.$locale.base}/places#category-5`}},[_vm._v(" "+_vm._s(_vm.$laptop ? _vm.$t('home.watch_all.p_1') : '')+" "+_vm._s(_vm.$t('home.watch_all.p_2'))+" ")]),_c('Icon',{staticClass:"thematic-slider__see-all-icon",attrs:{"viewport":"0 0 20 20","rotate":90,"xlink":"arrow-navigation"}})],1)],1)],2)],1):_vm._e(),_c('Section',[_c('ThematicSlider',{attrs:{"params":{array: _vm.$news.data,
        component: 'NewsCard',
        count: _vm.count}}},[_c('template',{slot:"thematic-slider-title"},[_c('h2',{staticClass:"thematic-slider__title"},[_vm._v(_vm._s(_vm.$t('home.news')))])]),_c('template',{slot:"thematic-slider-button"},[_c('Row',{staticClass:"thematic-slider__see-all",attrs:{"align":"center"}},[_c('router-link',{staticClass:"thematic-slider__see-all-text",attrs:{"to":`${_vm.$locale.base}/news`}},[_vm._v(" "+_vm._s(_vm.$laptop ? _vm.$t('home.watch_all.p_1') : '')+" "+_vm._s(_vm.$t('home.watch_all.p_2'))+" ")]),_c('Icon',{staticClass:"thematic-slider__see-all-icon",attrs:{"viewport":"0 0 20 20","rotate":90,"xlink":"arrow-navigation"}})],1)],1)],2)],1),(_vm.$showIfDefaultCity)?_c('Section',{attrs:{"limiter":!_vm.$mobile}},[_c('FormationPortal',{attrs:{"backgroundImage":_vm.$laptop ?
        require('@/assets/images/mobile.jpg') : ''}},[_c('template',{slot:"formation-portal-title"},[_c('span',{staticClass:"formation-portal__title"},[_vm._v(_vm._s(_vm.$t('home.app.p_1'))+" "),_c('br'),_vm._v(" "+_vm._s(_vm.$t('home.app.p_2'))+" ")])]),_c(_vm.$mobile ? 'Column' : 'Row',{tag:"component",class:_vm.$mobile ? '' : '-m-15',attrs:{"full":_vm.$mobile}},[_c('a',{staticClass:"home-view__app-icon",attrs:{"href":_vm.appStoreHref}},[_c('img',{staticClass:"icon-app",attrs:{"src":require('@/assets/images/svg/appstore.svg'),"alt":""}})]),_c('a',{staticClass:"home-view__app-icon",attrs:{"href":_vm.googlePlayHref}},[_c('img',{staticClass:"icon-app",attrs:{"src":require('@/assets/images/svg/googleplay.svg'),"alt":""}})])])],2)],1):_vm._e(),_c('ModifiedBillboard')],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }