<template>
  <div class="home-view">
    <div class="home-view__wrapper">
      <Section>
        <HeroSlider v-show="heroSlider.length > 0" :params="heroSlider"/>
      </Section>
      <transition name="fade">
        <Section v-if="showArticles">
          <ReasonsVisitArea>
            <template slot="reasons-visit-area-title">
              <h2 class="reasons-visit-area__title">{{ $t('home.reasons') }} {{ $city.title }}</h2>
            </template>
          </ReasonsVisitArea>
        </Section>
      </transition>
      <Section v-if="samoeInteresnoeVGorode.length > 0">
        <ThematicSlider :params="{array: samoeInteresnoeVGorode,
          component: 'PlaceCard',
          count}">
          <template slot="thematic-slider-title">
            <h2 class="thematic-slider__title">{{ $t('home.interesting') }}</h2>
          </template>
          <template slot="thematic-slider-button">
            <Row align="center"
              class="thematic-slider__see-all">
              <router-link class="thematic-slider__see-all-text"
                :to="`${$locale.base}/places#category-1`">
                {{$laptop ? $t('home.watch_all.p_1') : ''}} {{ $t('home.watch_all.p_2') }}
              </router-link>
              <Icon class="thematic-slider__see-all-icon"
                viewport="0 0 20 20"
                :rotate="90"
                xlink="arrow-navigation"/>
            </Row>
          </template>
        </ThematicSlider>
      </Section>
      <Section v-if="$showIfDefaultCity"
        :limiter="!$mobile">
        <FormationPortal :backgroundImage="$laptop ?
          require('@/assets/images/panels.jpg') : ''">
          <template slot="formation-portal-sup-title">
            <span class="formation-portal__sup-title">{{ $t('home.banner.infrastructure') }}</span>
          </template>
          <template slot="formation-portal-title">
            <span class="formation-portal__title">{{ $t('home.banner.panels') }}</span>
          </template>
          <template slot="formation-portal-description">
            <span class="formation-portal__description">{{ $t('home.banner.desc.p_1') }}
              <br> {{ $t('home.banner.desc.p_2') }}</span>
          </template>
          <Button :full="$mobile"
            color="transparent"
            v-on:click.native="toLink(`${$locale.base}/info`)">
            {{$mobile ? $t('home.banner.btn_2') : $t('home.banner.btn_1')}}
          </Button>
        </FormationPortal>
      </Section>
      <Section v-if="ozdorovlenieVZheleznovodske.length > 0">
        <ThematicSlider :params="{
            array: ozdorovlenieVZheleznovodske,
            component: 'PlaceCard',
            count
          }"
        >
          <template slot="thematic-slider-title">
            <h2 class="thematic-slider__title">
              {{ $t('home.health') }} {{$city.declension}}
            </h2>
          </template>
          <template slot="thematic-slider-button">
            <Row align="center"
              class="thematic-slider__see-all">
              <router-link class="thematic-slider__see-all-text"
                :to="`${$locale.base}/places#category-5`">
                {{$laptop ? $t('home.watch_all.p_1') : ''}} {{ $t('home.watch_all.p_2') }}
              </router-link>
              <Icon class="thematic-slider__see-all-icon"
                viewport="0 0 20 20"
                :rotate="90"
                xlink="arrow-navigation"/>
            </Row>
          </template>
        </ThematicSlider>
      </Section>
      <Section>
        <ThematicSlider :params="{array: $news.data,
          component: 'NewsCard',
          count}">
          <template slot="thematic-slider-title">
            <h2 class="thematic-slider__title">{{ $t('home.news') }}</h2>
          </template>
          <template slot="thematic-slider-button">
            <Row align="center"
              class="thematic-slider__see-all">
              <router-link class="thematic-slider__see-all-text"
                :to="`${$locale.base}/news`">
                {{$laptop ? $t('home.watch_all.p_1') : ''}} {{ $t('home.watch_all.p_2') }}
              </router-link>
              <Icon class="thematic-slider__see-all-icon"
                viewport="0 0 20 20"
                :rotate="90"
                xlink="arrow-navigation"/>
            </Row>
          </template>
        </ThematicSlider>
      </Section>
      <Section v-if="$showIfDefaultCity"
        :limiter="!$mobile">
        <FormationPortal :backgroundImage="$laptop ?
          require('@/assets/images/mobile.jpg') : ''">
          <template slot="formation-portal-title">
            <span class="formation-portal__title">{{ $t('home.app.p_1') }} <br>
              {{ $t('home.app.p_2') }}
            </span>
          </template>
          <component :is="$mobile ? 'Column' : 'Row'"
            :full="$mobile"
            :class="$mobile ? '' : '-m-15'">
            <a class="home-view__app-icon"
              :href="appStoreHref">
              <img class="icon-app"
                :src="require('@/assets/images/svg/appstore.svg')" alt/>
            </a>
            <a class="home-view__app-icon"
              :href="googlePlayHref">
              <img class="icon-app"
                :src="require('@/assets/images/svg/googleplay.svg')" alt/>
            </a>
          </component>
        </FormationPortal>
      </Section>
      <ModifiedBillboard/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeView',
  data() {
    return {
      dates: {
        started: this.$moment()
          .date(this.$moment()
            .date())
          .format('YYYY-MM-DD'),
        stopped: null,
      },
      date: '',
      appStoreHref: process.env.VUE_APP_DEFAULTH_MOBILE_APPLE_LINK,
      googlePlayHref: process.env.VUE_APP_DEFAULTH_MOBILE_ANDROID_LINK,
    };
  },
  created() {
    this.$store.dispatch('GET_HERO_SLIDER_FROM_SERVER_WITH_PARAMS', {
      clear: true,
      params: {
        city_id: this.$city.id,
        count: 9,
        lang: this.$i18n.locale,
      },
    });
    this.$store.dispatch('GET_ARTICLES_FROM_SERVER_WITH_PARAMS', {
      clear: true,
      params: {
        resolution: 'medium',
        city_id: this.$city.id,
        count: 9,
        lang: this.$i18n.locale,
      },
    });
    this.$store.dispatch('GET_NEWS_FROM_SERVER_WITH_PARAMS', {
      clear: true,
      params: {
        resolution: 'medium',
        city_id: this.$city.id,
        count: 9,
        lang: this.$i18n.locale,
      },
    });
    this.$store.dispatch('GET_EVENTS_FROM_SERVER_WITH_PARAMS', {
      clear: true,
      params: {
        resolution: 'medium',
        city_id: this.$city.id,
        count: 9,
        started_at: this.dates.started,
        stopped_at: this.dates.stopped,
        lang: this.$i18n.locale,
      },
    });
    this.$store.dispatch('GET_STORIES_FROM_SERVER_WITH_PARAMS', {
      clear: true,
      params: {
        resolution: 'medium',
        city_id: this.$city.id,
        count: 9,
        lang: this.$i18n.locale,
      },
    });
  },
  computed: {
    heroSlider() {
      return this?.$heroSlider?.data;
    },
    showArticles() {
      return this?.$articles?.data?.length > 0;
    },
    count() {
      let count;
      if (this.$laptop) count = 3;
      if (this.$tablet) count = 2;
      if (this.$mobile) count = 1;
      return count;
    },
    samoeInteresnoeVGorode() {
      if (this?.$promo?.sliders?.length > 0) {
        const slider = this?.$promo?.sliders?.find((item) => item.position === 'main-page-first');
        return [...slider?.places || [], ...slider?.trips || [], ...slider?.events || []] || [];
      }
      return [];
    },
    ozdorovlenieVZheleznovodske() {
      if (this?.$promo?.sliders?.length > 0) {
        const slider = this?.$promo?.sliders?.find((item) => item.position === 'main-page-second');
        return [...slider?.places || [], ...slider?.trips || [], ...slider?.events || []] || [];
      }
      return [];
    },
  },
  methods: {
    removeDate() {
      this.dates.started = this.$moment()
        .date(this.$moment()
          .date())
        .format('YYYY-MM-DD');
      this.dates.stopped = null;
      this.date = '';
      this.request();
    },
    changeColor() {
    },
    toLink(link) {
      this.$router.push(link);
    },
    setDate(event) {
      this.date = event;
      this.dates.started = this.$moment(event)
        .format('YYYY-MM-DD');
      this.dates.stopped = this.$moment(event)
        .format('YYYY-MM-DD');
      this.request();
    },
    today() {
      this.dates.started = this.$moment()
        .format('YYYY-MM-DD');
      this.dates.stopped = this.$moment()
        .format('YYYY-MM-DD');
      this.date = this.$moment();
      this.request();
    },
    tomorrow() {
      this.dates.started = this.$moment()
        .date(this.$moment()
          .date() + 1)
        .format('YYYY-MM-DD');
      this.dates.stopped = this.$moment()
        .date(this.$moment()
          .date() + 1)
        .format('YYYY-MM-DD');
      this.date = this.$moment()
        .date(this.$moment()
          .date() + 1);
      this.request();
    },
    request() {
      this.$store.dispatch('GET_EVENTS_FROM_SERVER_WITH_PARAMS', {
        clear: true,
        params: {
          resolution: 'medium',
          count: 100500,
          city_id: this.$city.id,
          started_at: this.dates.started,
          stopped_at: this.dates.stopped,
          lang: this.$i18n.locale,
        },
      });
    },
  },
};
</script>
